import { Theme } from '@emotion/react';
import { LinkSize, LinkVariant } from '@ui-v2/types/buttons';
import { ThemeResponsiveProp } from '@ui-v2/types/props';

type LinkSizeStyleConfig = {
  fontSize?: ThemeResponsiveProp<number>;
};

export const buildLinkSize = (size: LinkSize): LinkSizeStyleConfig => {
  switch (size) {
    case 'small': {
      return {
        fontSize: 14,
      };
    }

    case 'regular': {
      return {
        fontSize: 16,
      };
    }

    case 'large': {
      return {
        fontSize: 18,
      };
    }
  }
};

type LinkVariantStyleConfig = {
  '&:active': {
    '&:after': {
      backgroundColor: string;
    };

    color: string;
  };
  '&:after': {
    backgroundColor: string;
  };
  '&:focus': {
    '&:after': {
      backgroundColor: string;
    };

    color: string;
  };
  '&:hover': {
    '&:after': {
      backgroundColor: string;
    };

    color: string;
  };
  color: string;
};

export const buildLinkVariant = (
  variant: LinkVariant,
  theme: Theme,
): LinkVariantStyleConfig => {
  switch (variant) {
    case 'primary': {
      return {
        color: theme.colours.text.link.default,

        '&:after': {
          backgroundColor: theme.colours.text.link.default,
        },

        '&:hover': {
          color: theme.colours.text.link.hover,

          '&:after': {
            backgroundColor: theme.colours.text.link.hover,
          },
        },

        '&:active': {
          color: theme.colours.text.link.pressed,

          '&:after': {
            backgroundColor: theme.colours.text.link.pressed,
          },
        },
        '&:focus': {
          color: theme.colours.text.link.pressed,

          '&:after': {
            backgroundColor: theme.colours.text.link.pressed,
          },
        },
      };
    }

    case 'tertiary': {
      return {
        color: theme.colours.text.default,

        '&:after': {
          backgroundColor: theme.colours.text.default,
        },

        '&:hover': {
          color: theme.colours.text.subdued,

          '&:after': {
            backgroundColor: theme.colours.text.subdued,
          },
        },

        '&:active': {
          color: theme.colours.text.link.default,

          '&:after': {
            backgroundColor: theme.colours.text.link.default,
          },
        },
        '&:focus': {
          color: theme.colours.text.link.default,

          '&:after': {
            backgroundColor: theme.colours.text.link.default,
          },
        },
      };
    }
  }
};
