import { Passenger, usePatchOfferIdPassengers } from '@codegen/offerAPI';
import { parseQueryString } from '@utils/queryUtils';
import { useErrorOrchestrator } from '@web/context/ErrorOrchestratorContext';
import useGetOffer from '@web/context/hooks/useGetOffer';
import usePartnerRouter from '@web/context/hooks/usePartnerRouter';
import usePartnerPassengersConfig from '@web/context/hooks/usePassengersConfig';
import {
  constructLoadingPassengers,
  findGenderOfPax,
  parsePassengerGenders,
  parsePassengerTitles,
} from '@web/utils/booking/passengerUtils';
import useBookingInputValidationRules from './useBookingInputValidationRules';
import { OfferMutations } from './useIsUpdating';
import useVendorPassengerRules from './useVendorPassengerRules';

export const usePassengers = () => {
  const {
    back,
    query: { n_adults, youngsters_ages },
  } = usePartnerRouter();

  const { showError } = useErrorOrchestrator();

  const { isFetchingOffer, isOfferLoading, offer } = useGetOffer();
  // We use titles from Partners, but age config from vendors
  const { passengerGenders, passengerTitles } = usePartnerPassengersConfig();
  const iatas = offer
    ? offer.bundle_groups.reduce<string[]>(
        (acc, curr) => [...acc, ...curr.iatas],
        [],
      )
    : [];

  const { enforceUniqueFullName, vendorPassengerRules } =
    useVendorPassengerRules(iatas);

  const { passengerValidations } = useBookingInputValidationRules(iatas);

  const { mutateAsync } = usePatchOfferIdPassengers({
    mutation: {
      mutationKey: [OfferMutations.PASSENGER_INFO],
      retry: 2,
      onError: () => {
        return showError({
          action: back,
          errorContent: {
            message: 'Error when posting passengers',
            context: {
              passengers: offer?.passengers,
              isFetchingOffer,
              isOfferLoading,
            },
          },
        });
      },
    },
  });

  const parsedPassengerTitles = parsePassengerTitles(passengerTitles);

  const postPassengers = (passengers: Passenger[]) =>
    mutateAsync({
      offerId: offer?.offer_id as string,
      data: {
        passengers: passengers.map((pax) => ({
          ...pax,
          first_name: pax.first_name?.trim(),
          last_name: pax.last_name?.trim(),
          passport_number: pax.passport_number?.trim(),
          gender: pax.gender ?? findGenderOfPax(pax.title, passengerTitles),
          frequent_flyer_numbers: pax.frequent_flyer_numbers,
        })),
      },
    });

  return {
    passengers:
      isFetchingOffer || isOfferLoading || !offer?.passengers
        ? constructLoadingPassengers({
            numberOfAdults: parseQueryString(n_adults),
            youngstersAges: parseQueryString(youngsters_ages),
            passengerTitles: parsedPassengerTitles,
            currentPassengers: [],
            vendorPassengerRules,
          })
        : offer.passengers,
    passengerValidations,
    vendorPassengerRules,
    passengerTitles: parsedPassengerTitles,
    passengerGenders: parsePassengerGenders(passengerGenders),
    isLoading: isOfferLoading,
    postPassengers,
    enforceUniqueFullName,
  };
};
