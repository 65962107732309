import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { hexToRGBA } from '@ui-v2/utils/styleUtils';
import { buildMargin } from '@ui-v2/utils/themePropBuilders';
import {
  LinkInherentProps,
  TRANSITION_SPEED,
  stylizedUnderlineStyles,
} from './Link';
import { buildLinkSize, buildLinkVariant } from './variants';

export type LinkAsButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'ref'
> &
  LinkInherentProps;

const StyledLinkAsButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'underlineStyle',
})<
  LinkAsButtonProps & {
    size: NonNullable<LinkAsButtonProps['size']>;
    variant: NonNullable<LinkAsButtonProps['variant']>;
  }
>(({ size, theme, underlineStyle, variant, ...props }) => [
  css`
    position: relative;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    line-height: ${theme.typography.body01.lineHeightPercent}%;
    outline: none;
    transition: color ${TRANSITION_SPEED};

    &:focus {
      background: ${hexToRGBA(theme.colours.surface.information, 0.5)};
    }
  `,
  underlineStyle === 'stylized' && stylizedUnderlineStyles,
  css(
    buildResponsiveValues({
      ...buildMargin(props),
      ...buildLinkVariant(variant, theme),
      ...buildLinkSize(size),
    }),
  ),
]);

// eslint-disable-next-line react/display-name
const LinkAsButton = forwardRef<HTMLButtonElement, LinkAsButtonProps>(
  (
    {
      children,
      size = 'regular',
      underlineStyle = 'stylized',
      variant = 'primary',
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <StyledLinkAsButton
        ref={forwardedRef}
        size={size}
        underlineStyle={underlineStyle}
        variant={variant}
        {...props}
      >
        {children}
      </StyledLinkAsButton>
    );
  },
);

export default LinkAsButton;
