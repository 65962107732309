import { Fragment } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Box from '@ui-v2/core/Box/Box';
import Container from '@ui-v2/core/Container/Container';
import Link from '@ui-v2/core/Link/Link';
import LinkAsButton from '@ui-v2/core/Link/LinkAsButton';
import usePartnerFooter from '@web/context/hooks/usePartnerFooter';
import usePartnerInfo from '@web/context/hooks/usePartnerInfo';
import FooterItemContent from './FooterItemContent';

const Footer = () => {
  const { t } = useTranslation();
  const footerData = usePartnerFooter();
  const { trackingConfig } = usePartnerInfo();

  if (!footerData?.footerItems && !trackingConfig?.usercentricsId) {
    return null;
  }

  return (
    <Box bg="surface.main">
      <Container>
        <Box
          alignItems={['center', 'center', 'center', 'baseline']}
          display="flex"
          flexDirection={['column', 'column', 'column', 'row']}
          gap={16}
          justifyContent="space-around"
          py={16}
          width="100%"
        >
          {footerData?.footerItems.map((item) => (
            <Fragment key={item.id}>
              {item.link && (
                <Link
                  href={item.link.href}
                  size="small"
                  target={item.link.target ?? '__blank'}
                  variant="tertiary"
                >
                  <FooterItemContent
                    image={item.image}
                    label={item.label?.value ?? item.link.label.value}
                  />
                </Link>
              )}
              {!item.link && item.image && (
                <FooterItemContent
                  image={item.image}
                  label={item.label?.value}
                />
              )}
            </Fragment>
          ))}
          {trackingConfig?.usercentricsId && (
            <LinkAsButton
              onClick={() => window.UC_UI?.showSecondLayer()}
              size="small"
              variant="tertiary"
            >
              {t('Privacy Settings')}
            </LinkAsButton>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
